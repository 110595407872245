.priorityTemplate {
    width: 100px;
}

.priorityTemplate, .statusTemplate, .lastEditedTemplate {
    width: 145px;
    /* width: fit-content; */
}

.statusTemplateColumn, .lastEditedTemplateColumn, .priorityTemplateColumn {
    width: 150px;
}

.table-header {
    display: flex;
    justify-content: space-between;
}

.dropdown-status {
    min-width: 190px;
    
}

.button-space {
    margin-right: 5px !important;
    margin-bottom: 5px !important;
}

.todoForm {
    min-height: 70px;
}

.todoFormSubmit {
    width: 500px;
    max-width: 500px;
}


.table-header {
    display: flex;
    justify-content: space-between;
}

@media screen and (max-width: 600px) {
    .table-header {
        flex-direction: column;
    }
    .table-header > div {
        margin-bottom: 5px !important;
    }
    .dropdown-status {
        width: 100%;
    }
}